/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import "../has.js";
import t from "../Logger.js";
import { pathToArray as n, getProperties as r } from "./utils.js";
import { Flags as e } from "./tracking/Flags.js";
import { TrackingTarget as i } from "./tracking/TrackingTarget.js";
const o = new i(),
  c = [];
let s = o;
function a(t) {
  s.onAccessed(t);
}
let l = !1,
  g = !1;
function f(t, n, r) {
  if (l) return p(t, n, r);
  let e = null;
  return k(t), e = n.call(r), m(), e;
}
function u(t, n) {
  return f(o, t, n);
}
function p(n, r, e) {
  const i = l;
  l = !0, k(n);
  let o = null;
  try {
    o = r.call(e);
  } catch (c) {
    g && t.getLogger("esri.core.accessorSupport.tracking").error(c);
  }
  return m(), l = i, o;
}
function k(t) {
  s = t, c.push(t);
}
function m() {
  const t = c.length;
  if (t > 1) {
    const n = c.pop();
    s = c[t - 2], n.onTrackingEnd();
  } else if (1 === t) {
    const t = c.pop();
    s = o, t.onTrackingEnd();
  } else s = o;
}
function d(t, n) {
  if (n.flags & e.DepTrackingInitialized) return;
  n.flags |= e.DepTrackingInitialized;
  const r = g;
  g = !1, n.flags & e.AutoTracked ? p(n.trackingTarget, n.metadata.get, t) : y(t, n), g = r;
}
const T = [];
function y(t, r) {
  r.flags & e.ExplicitlyTracking || (r.flags |= e.ExplicitlyTracking, p(r.trackingTarget, () => {
    const e = r.metadata.dependsOn || T;
    for (const r of e) if ("string" != typeof r || r.includes(".")) {
      const e = n(r);
      for (let n = 0, r = t; n < e.length && null != r && "object" == typeof r; ++n) r = h(r, e[n], n !== e.length - 1);
    } else h(t, r, !1);
  }), r.flags &= ~e.ExplicitlyTracking);
}
function h(t, n, e) {
  const i = "?" === n[n.length - 1] ? n.slice(0, -1) : n;
  if (null != t.getItemAt || Array.isArray(t)) {
    const n = parseInt(i, 10);
    if (!isNaN(n)) return Array.isArray(t) ? t[n] : t.at(n);
  }
  const o = r(t);
  if (o) {
    const n = o.propertiesByName.get(i);
    n && (a(n), d(t, n));
  }
  return e ? t[i] : void 0;
}
export { d as initializeDependencyTracking, f as runTracked, p as runTrackedNoThrow, u as runUntracked, a as trackAccess, y as trackExplicitDependencies };