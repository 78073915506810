/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import t from "../../../Logger.js";
import { setDeepValue as e } from "../../../object.js";
import r from "../../../Warning.js";
import { getPropertiesMetadata as n } from "../../metadata.js";
import { isCollection as o } from "./type.js";
const i = () => t.getLogger("esri.core.accessorSupport.extensions.serializableProperty.reader");
function p(t, r, n) {
  t && (!n && !r.read || r.read?.reader || !1 === r.read?.enabled || l(t) && e("read.reader", u(t), r));
}
function u(t) {
  const e = t.ndimArray ?? 0;
  if (e > 1) return c(t);
  if (1 === e) return a(t);
  if ("type" in t && d(t.type)) {
    const e = t.type.prototype?.itemType?.Type,
      r = a("function" == typeof e ? {
        type: e
      } : {
        types: e
      });
    return (e, n, o) => {
      const i = r(e, n, o);
      return i ? new t.type(i) : i;
    };
  }
  return s(t);
}
function s(t) {
  return "type" in t ? y(t.type) : g(t.types);
}
function y(t) {
  return t.prototype.read ? (e, r, n) => {
    if (null == e) return e;
    const o = typeof e;
    if ("object" !== o) return void i().error(`Expected JSON value of type 'object' to deserialize type '${t.prototype.declaredClass}', but got '${o}'`);
    const p = new t();
    return p.read(e, n), p;
  } : t.fromJSON;
}
function f(t, e, r, n) {
  return 0 !== n && Array.isArray(e) ? e.map(e => f(t, e, r, n - 1)) : t(e, void 0, r);
}
function c(t) {
  const e = s(t),
    r = f.bind(null, e),
    n = t.ndimArray ?? 0;
  return (t, e, o) => {
    if (null == t) return t;
    t = r(t, o, n);
    let i = n,
      p = t;
    for (; i > 0 && Array.isArray(p);) i--, p = p[0];
    if (void 0 !== p) for (let r = 0; r < i; r++) t = [t];
    return t;
  };
}
function a(t) {
  const e = s(t);
  return (t, r, n) => {
    if (null == t) return t;
    if (Array.isArray(t)) {
      const r = [];
      for (const o of t) {
        const t = e(o, void 0, n);
        void 0 !== t && r.push(t);
      }
      return r;
    }
    const o = e(t, void 0, n);
    return void 0 !== o ? [o] : void 0;
  };
}
function d(t) {
  if (!o(t)) return !1;
  const e = t.prototype.itemType;
  return !(!e || !e.Type) && ("function" == typeof e.Type ? m(e.Type) : j(e.Type));
}
function l(t) {
  return "types" in t ? j(t.types) : m(t.type);
}
function m(t) {
  return !Array.isArray(t) && !!t && t.prototype && ("read" in t.prototype || "fromJSON" in t || d(t));
}
function j(t) {
  for (const e in t.typeMap) {
    if (!m(t.typeMap[e])) return !1;
  }
  return !0;
}
function g(t) {
  let e = null;
  const n = t.errorContext ?? "type",
    o = t.validate;
  return (p, u, s) => {
    if (null == p) return p;
    const y = typeof p;
    if ("object" !== y) return void i().error(`Expected JSON value of type 'object' to deserialize, but got '${y}'`);
    e || (e = v(t));
    const f = t.key;
    if ("string" != typeof f) return;
    const c = p[f],
      a = c ? e[c] : t.defaultKeyValue ? t.typeMap[t.defaultKeyValue] : void 0;
    if (!a) {
      const t = `Type '${c || "unknown"}' is not supported`;
      return s?.messages && p && s.messages.push(new r(`${n}:unsupported`, t, {
        definition: p,
        context: s
      })), void i().error(t);
    }
    const d = new a();
    return d.read(p, s), o ? o(d) : d;
  };
}
function v(t) {
  const e = {};
  for (const r in t.typeMap) {
    const o = t.typeMap[r],
      i = n(o.prototype);
    if ("function" == typeof t.key) continue;
    const p = i[t.key];
    if (!p) continue;
    if (p.json?.type && Array.isArray(p.json.type) && 1 === p.json.type.length && "string" == typeof p.json.type[0]) {
      e[p.json.type[0]] = o;
      continue;
    }
    const u = p.json?.write;
    if (!u?.writer) {
      e[r] = o;
      continue;
    }
    const s = u.target,
      y = "string" == typeof s ? s : t.key,
      f = {};
    u.writer(r, f, y), f[y] && (e[f[y]] = o);
  }
  return e;
}
export { p as create, u as createTypeReader };