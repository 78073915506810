/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { clone as t } from "../lang.js";
import { merge as e } from "./utils.js";
function r(e) {
  let r = e.constructor.__accessorMetadata__;
  const o = Object.prototype.hasOwnProperty.call(e.constructor, "__accessorMetadata__");
  if (r) {
    if (!o) {
      r = Object.create(r);
      for (const e in r) r[e] = t(r[e]);
      Object.defineProperty(e.constructor, "__accessorMetadata__", {
        value: r,
        enumerable: !1,
        configurable: !0,
        writable: !0
      });
    }
  } else r = {}, Object.defineProperty(e.constructor, "__accessorMetadata__", {
    value: r,
    enumerable: !1,
    configurable: !0,
    writable: !0
  });
  return e.constructor.__accessorMetadata__;
}
function o(t, e) {
  const o = r(t);
  let c = o[e];
  return c || (c = o[e] = {}), c;
}
function c(t, r) {
  return e(t, r, n);
}
const a = /^(?:[^.]+\.)?(?:value|type|(?:json\.type|json\.origins\.[^.]\.type))$/;
function n(t) {
  return a.test(t) ? "replace" : "merge";
}
export { r as getPropertiesMetadata, o as getPropertyMetadata, c as mergeProperty };