/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
function n(n) {
  if (n.json && n.json.origins) {
    const o = n.json.origins,
      e = {
        "web-document": ["web-scene", "web-map"]
      };
    for (const n in e) if (o[n]) {
      const s = o[n];
      e[n].forEach(n => {
        o[n] = s;
      }), delete o[n];
    }
  }
}
export { n as process };