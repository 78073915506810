/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
class s {
  constructor(s) {
    this.accessed = void 0, this._handles = void 0, this._observer = s;
  }
  destroy() {
    this.accessed?.clear(), this.clear();
  }
  onAccessed(s) {
    null != this._observer && s !== this._observer && (null == this.accessed && (this.accessed = new Set()), this.accessed.add(s));
  }
  onTrackingEnd() {
    null != this._observer && (this.clear(), null != this.accessed && (null == this._handles && (this._handles = []), this.accessed.forEach(s => {
      this._handles.push(s.observe(this._observer));
    }), this.accessed.clear()));
  }
  clear() {
    if (null != this._handles) {
      for (let s = 0; s < this._handles.length; ++s) this._handles[s].remove();
      this._handles.length = 0;
    }
  }
}
export { s as TrackingTarget };