/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
function n(n, t) {
  for (const r of n.values()) if (t(r)) return !0;
  return !1;
}
function t(n, t) {
  for (const r of n.values()) if (t(r)) return r;
  return null;
}
function r(n, t, r) {
  const e = n.get(t);
  if (void 0 !== e) return e;
  const o = r();
  return n.set(t, o), o;
}
function e(n) {
  const t = new Map();
  return r => (t.has(r) || t.set(r, n(r)), t.get(r));
}
export { t as findInMap, r as getOrCreateMapValue, e as memoize, n as someMap };