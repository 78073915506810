/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import "../has.js";
import e from "../Logger.js";
import { getOrCreateMapValue as n } from "../MapUtils.js";
const r = () => e.getLogger("esri.core.accessorSupport.ensureTypes");
function t(e) {
  return null == e ? e : new Date(e);
}
function o(e) {
  return null == e ? e : !!e;
}
function u(e) {
  return null == e ? e : e.toString();
}
function a(e, n = 0) {
  return null == e ? e : (e = parseFloat(e), isNaN(e) ? n : e);
}
function s(e) {
  return null == e ? e : Math.round(parseFloat(e));
}
function l(e) {
  return null;
}
function i(e, n) {
  return r => {
    let t = e(r);
    return null != n.step && (t = Math.round(t / n.step) * n.step), null != n.min && (t = Math.max(n.min, t)), null != n.max && (t = Math.min(n.max, t)), t;
  };
}
function c(e) {
  return e?.constructor && void 0 !== e.constructor.__accessorMetadata__;
}
function f(e, n) {
  return null != n && e && !(n instanceof e);
}
function p(e) {
  return e && "isCollection" in e;
}
function y(e) {
  return e?.Type ? "function" == typeof e.Type ? e.Type : e.Type.base : null;
}
function v(e, n) {
  if (!n?.constructor || !p(n.constructor)) return g(e, n) ? n : new e(n);
  const r = y(e.prototype.itemType),
    t = y(n.constructor.prototype.itemType);
  return r ? t ? r === t ? n : r.prototype.isPrototypeOf(t.prototype) ? new e(n) : (g(e, n), n) : new e(n) : n;
}
function g(e, n) {
  return !!c(n) && (r().error("Accessor#set", "Assigning an instance of '" + (n.declaredClass || "unknown") + "' which is not a subclass of '" + m(e) + "'"), !0);
}
function h(e, n) {
  return null == n ? n : p(e) ? v(e, n) : f(e, n) ? g(e, n) ? n : new e(n) : n;
}
function m(e) {
  return e?.prototype?.declaredClass || "unknown";
}
const d = new WeakMap();
function b(e) {
  switch (e) {
    case Number:
      return e => a(e);
    case x:
      return s;
    case Boolean:
      return o;
    case String:
      return u;
    case Date:
      return t;
    case _:
      return l;
    default:
      return n(d, e, () => h.bind(null, e));
  }
}
function w(e, n) {
  const r = b(e);
  return 1 === arguments.length ? r : r(n);
}
function A(e, n, r) {
  return 1 === arguments.length ? A.bind(null, e) : n ? Array.isArray(n) ? n.map(n => e(n, r)) : [e(n, r)] : n;
}
function $(e, n) {
  return 1 === arguments.length ? A(n => w(e, n)) : A(n => w(e, n), n);
}
function j(e, n, r) {
  return 0 !== n && Array.isArray(r) ? r.map(r => j(e, n - 1, r)) : e(r);
}
function M(e, n, r) {
  if (2 === arguments.length) return r => M(e, n, r);
  if (!r) return r;
  r = j(e, n, r);
  let t = n,
    o = r;
  for (; t > 0 && Array.isArray(o);) t--, o = o[0];
  if (void 0 !== o) for (let u = 0; u < t; u++) r = [r];
  return r;
}
function k(e, n, r) {
  return 2 === arguments.length ? M(n => w(e, n), n) : M(n => w(e, n), n, r);
}
function S(e) {
  return !!Array.isArray(e) && !e.some(n => {
    const r = typeof n;
    return !("string" === r || "number" === r || "function" === r && e.length > 1);
  });
}
function T(e, n) {
  if (2 === arguments.length) return T(e).call(null, n);
  const t = new Set(),
    o = e.filter(e => "function" != typeof e),
    u = e.filter(e => "function" == typeof e);
  for (const r of e) "string" != typeof r && "number" != typeof r || t.add(r);
  let a = null,
    s = null;
  return (e, n) => {
    if (null == e) return e;
    const l = typeof e,
      i = "string" === l || "number" === l;
    return i && (t.has(e) || u.some(e => "string" === l && e === String || "number" === l && e === Number)) || "object" === l && u.some(n => !f(e, n)) ? e : (i && o.length ? (a || (a = o.map(e => "string" == typeof e ? `'${e}'` : `${e}`).join(", ")), r().error("Accessor#set", `'${e}' is not a valid value for this property, only the following values are valid: ${a}`)) : "object" == typeof e && u.length ? (s || (s = u.map(e => m(e)).join(", ")), r().error("Accessor#set", `'${e}' is not a valid value for this property, value must be one of ${s}`)) : r().error("Accessor#set", `'${e}' is not a valid value for this property`), n && (n.valid = !1), null);
  };
}
function N(e, n) {
  if (2 === arguments.length) return N(e).call(null, n);
  const t = {},
    o = [],
    u = [];
  for (const r in e.typeMap) {
    const n = e.typeMap[r];
    t[r] = w(n), o.push(m(n)), u.push(r);
  }
  const a = () => `'${o.join("', '")}'`,
    s = () => `'${u.join("', '")}'`,
    l = "string" == typeof e.key ? n => n[e.key] : e.key;
  return n => {
    if (e.base && !f(e.base, n)) return n;
    if (null == n) return n;
    const o = l(n) || e.defaultKeyValue,
      u = t[o];
    if (!u) return r().error("Accessor#set", `Invalid property value, value needs to be one of ${a()}, or a plain object that can autocast (having .type = ${s()})`), null;
    if (!f(e.typeMap[o], n)) return n;
    if ("string" == typeof e.key && !c(n)) {
      const r = {};
      for (const t in n) t !== e.key && (r[t] = n[t]);
      return u(r);
    }
    return u(n);
  };
}
class x {}
class _ {}
const B = {
  native: e => ({
    type: "native",
    value: e
  }),
  array: e => ({
    type: "array",
    value: e
  }),
  oneOf: e => ({
    type: "one-of",
    values: e
  })
};
function C(e) {
  if (!e || !("type" in e)) return !1;
  switch (e.type) {
    case "native":
    case "array":
    case "one-of":
      return !0;
  }
  return !1;
}
function D(e) {
  switch (e.type) {
    case "native":
      return w(e.value);
    case "array":
      return A(D(e.value));
    case "one-of":
      return F(e);
    default:
      return null;
  }
}
function F(e) {
  let n = null;
  return (t, o) => L(t, e) ? t : (null == n && (n = I(e)), r().error("Accessor#set", `Invalid property value, value needs to be of type ${n}`), o && (o.valid = !1), null);
}
function I(e) {
  switch (e.type) {
    case "native":
      switch (e.value) {
        case Number:
          return "number";
        case String:
          return "string";
        case Boolean:
          return "boolean";
        case x:
          return "integer";
        case Date:
          return "date";
        case _:
          return "null";
        default:
          return m(e.value);
      }
    case "array":
      return `array of ${I(e.value)}`;
    case "one-of":
      {
        const n = e.values.map(e => I(e));
        return `one of ${n.slice(0, -1)} or ${n[n.length - 1]}`;
      }
  }
  return "unknown";
}
function L(e, n) {
  if (null == e) return !0;
  switch (n.type) {
    case "native":
      switch (n.value) {
        case Number:
        case x:
          return "number" == typeof e;
        case Boolean:
          return "boolean" == typeof e;
        case String:
          return "string" == typeof e;
        case _:
          return null === e;
      }
      return e instanceof n.value;
    case "array":
      return !!Array.isArray(e) && !e.some(e => !L(e, n.value));
    case "one-of":
      return n.values.some(n => L(e, n));
  }
}
export { x as Integer, _ as Null, $ as ensureArray, A as ensureArrayTyped, o as ensureBoolean, h as ensureClass, t as ensureDate, s as ensureInteger, D as ensureLongFormType, k as ensureNArray, M as ensureNArrayTyped, l as ensureNull, a as ensureNumber, T as ensureOneOf, N as ensureOneOfType, i as ensureRange, u as ensureString, w as ensureType, c as isClassedType, C as isLongFormType, S as isOneOf, f as requiresType, B as types };